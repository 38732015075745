.calc-form {
    width: fit-content;
    margin: 40px 0;
    padding: 20px;
    background-color: rgb(222, 236, 228);
    border-radius: 15px;
    position: relative;

    .input-group-text {
        width: 72px;
    }

    .error {
        color: red;
    }

    button {
        position: relative;
        left: 50%;
        transform: translate(-50%);
    }
}