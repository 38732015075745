.App {
  .navbar-brand {
    font-size: 30px;
  }

  .container-main {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;

    .images {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 250px;

      img {
        width: 150px;
        object-fit: contain;

        &:first-of-type {
          margin-bottom: 50px;
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    .container-main {
      margin-top: 20px;
      flex-direction: column;

      .images {
        margin-top: 30px;
        // margin-bottom: 50px;
      }
    }
  }
}